<template>
  <div class="create-order">
    <el-dialog title="自動計算找續" :visible.sync="showCheckout" width="30%">
      <span>請輸入已付金額</span>
      <el-input v-model="payed" placeholder="請輸入已付金額"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCheckout = false">取消</el-button>
        <el-button type="primary" @click="getChange">確定</el-button>
      </span>
    </el-dialog>
    <div class="page-content">
      <div class="order-wrapper">
        <MealList />
        <div class="order-detail-wrapper">
          <h2>{{ $t('Create Order') }}</h2>
          <div class="order-seats">
            <button class="btn btn-primary" style="margin-bottom: 5px; margin-right: 5px;">
              {{ $t('Create Order') }}Barcode入單
            </button>
            <button class="btn btn-primary" style="margin-bottom: 5px; margin-right: 5px;">
              代收
            </button>
            <button class="btn btn-primary" style="margin-bottom: 5px;">
              代支
            </button>
            <div class="label">{{ $t('Table Number') }}</div>
            <input type="text" />
          </div>
          <div class="order-clear" v-if="mode === 'order'">
            <div class="clear-all">
              <div class="label">{{ $t('Clear') }}</div>
              <input type="checkbox" />
            </div>
            <div class="clear-number" v-if="mode === 'order'">
              <div class="label">{{ $t('Limted Clear') }}</div>
              <input type="number" v-model="clearNumber" />
            </div>
          </div>
          <div class="order-items">
            <div class="label">訂單{{ orderNumber }} 商品: </div>
            <ul class="order-items-list">
              <div class="order-item-wrapper" v-for="item in newOrderItems">
                <li class="order-item">
                  <div class="meal-name">
                    {{ item.name }}
                  </div>
                  <div class="offcanvas-cart-item-details-price">${{ getPrice(item.regular_price) }}</div>
                  <div v-if="clearNumber != 0">剩餘:{{ clearNumber - 1 }}</div>
                  <btn class="btn btn-primary">
                    分單
                  </btn>
                </li>
              </div>
            </ul>
          </div>
          <div class="set-meals" v-if="ableSetMeals.length !== 0">
            <div class="label">{{ $t('你的點餐可以搭配以下套餐:') }}</div>
            <ul class="order-items-list">
              <div class="order-item-wrapper" v-for="item in ableSetMeals">
                <li class="order-item">
                  <div class="meal-name">
                    {{ item.name }}
                  </div>
                  <div class="offcanvas-cart-item-details-price">${{ getPrice(item.regular_price) }}</div>
                  <div class="offcanvas-cart-item-details-price">
                    <btn class="btn btn-primary">
                      選擇撞餐
                    </btn>
                  </div>
                </li>
              </div>
            </ul>
          </div>
          <div class="order-amount">
            <div class="offcanvas-cart-total-price-text">{{ $t('Total:') }}</div>
            <div class="offcanvas-cart-total-price-value">${{ totalAmount }}</div>
            <div class="offcanvas-cart-total-price-text">{{ $t('已付金額:') }}</div>
            <div class="offcanvas-cart-total-price-value">${{ payed }}</div>
            <div class="offcanvas-cart-total-price-text">{{ $t('找續:') }}</div>
            <div class="offcanvas-cart-total-price-value">${{ change }}</div>
            <button class="btn btn-primary" @click="showCheckout = true" style="margin-top: 10px; margin-bottom: 20px;">
              自動計算找續
            </button>
          </div>
          <div class="payment-method-list" v-if="mode === 'checkout'">
            <div class="label">{{ $t('Payment Method:') }}</div>
            <div class="methods">
              <div class="method">現金付款</div>
              <div class="method"><img class="img-fluid"
                  src="https://img.openshop.com.hk/upload/202009/5f6a9ff13ae44.png" /></div>
              <div class="method"><img class="img-fluid"
                  src="https://img.openshop.com.hk/s2/202304/6437c5dd287a1.png" /></div>
            </div>
          </div>
          <button class="btn btn-primary btn-order" @click="createOrder" v-if="mode === 'order'">{{ $t('點餐') }}</button>
          <button class="btn btn-primary btn-order" @click="changeMode">{{ $t('結帳') }}</button>
          <button class="btn btn-primary btn-order">分單（賬單總金額）</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import MealList from '@/components/menu/MealList';
import Common from '@/lib/common';
import Order from '@/lib/order';
import Menu from '@/lib/menu';

export default {
  name: 'CreateOrder',
  components: {
    MealList
  },
  data() {
    return {
      clearNumber: 0,
      mode: 'order',
      ableSetMeals: [],
      showCheckout: false,
      payed: 0,
      change: 0,
      orderNumber: 1,
    };
  },
  methods: {
    getChange(){
      let change = this.payed - this.totalAmount;
      if(this.payed < this.totalAmount){
        change = 0;
      }
      this.change = change;
      this.showCheckout = false;
    },
    async changeMode() {
      this.mode = 'checkout';
    },
    async createOrder() {
      if (this.newOrderItems.length === 0) {
        this.$message({
          message: this.$t('You should add at lease one meal to order.'),
          type: 'error'
        });
        return;
      }
      const loginInfo = Common.getLoginInfo();
      const form = {
        customerId: null,
        meals: [],
        paymentMethod: 'POS',
      };
      for (let i = 0; i < this.newOrderItems.length; i++) {
        const item = this.newOrderItems[i];
        const meal = {
          id: item.id,
          quantity: item.quantity,
          price: item.regular_price,
        };
        form.meals.push(meal);
      }
      try {
        const order = await Order.createOrder(this.apiUrl, form, loginInfo);
        const orderId = order['order_id'];
        this.cleanOrderItem();
        this.$router.push({ name: 'CompleteOrder', params: { id: orderId } });
      } catch (err) {
        console.log(err);
      }
    },
    getPrice(price) {
      const priceNumber = Number(price);
      return priceNumber;
    },
    ...mapActions(['cleanOrderItem']),
  },
  watch: {
    newOrderItems(orderItemLists) {
      for (let i = 0; i < orderItemLists.length; i++) {
        const orderItem = orderItemLists[i];
        if (orderItem.related_set_meal.length !== 0) {
          const setMeals = orderItem.related_set_meal;
          const newList = this.ableSetMeals.concat(setMeals);
          //To concat without dulipcate
          this.ableSetMeals = newList.filter((item, pos) => newList.indexOf(item) === pos);
        }
      }
    },
  },
  computed: {
    ...mapState({
      newOrderItems: state => state.order.newOrderItems,
      langcode: state => state.langcode,
      apiUrl: state => state.apiUrl,
    }),
    ...mapGetters({
      totalAmount: 'totalAmount',
    })
  }
}
</script>

<style scoped lang="scss">
.create-order {
  .content-header {
    position: absolute;
    top: 0;
  }

  .page-content {
    padding-top: 5px;
    padding-right: 0;
    padding-bottom: 0;
    background-color: white;
    margin-right: 0;
    margin-bottom: 0;
  }

  .order-seats,
  .set-meals {
    margin-top: 20px;
  }

  .order-wrapper {
    display: flex;

    .meals {
      flex-basis: 70%;
      margin-right: 30px;
      padding-top: 30px;
    }
  }

  .order-detail-wrapper {
    background-color: #F6F6F6;
    flex-basis: 29%;
    padding-left: 30px;
    padding-top: 20px;

    h2 {
      margin: 8px 0 0;
      font-weight: 400;
      font-size: 17px;
      display: block;
      color: #008edd;
      border-bottom: none;
    }
  }

  .order-items,
  .set-meals {
    .label {
      margin-top: 30px;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .order-items-list {
      padding: 0;
      list-style: none;
    }

    .order-item {
      display: flex;
      margin-bottom: 10px;

      .meal-name {
        margin-right: 10px;
      }

      .offcanvas-cart-item-details-quantity {
        margin-right: 10px;
      }

      .offcanvas-cart-item-details-price {
        margin-right: 10px;
      }
    }
  }

  .order-amount {

    //display: flex;
    .offcanvas-cart-total-price-text {
      margin-right: 10px;
      font-weight: bold;
      margin-top: 10px;
    }
  }

  .clear-all {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;

    input {
      margin-left: 10px;
    }
  }

  .payment-method-list {
    margin-top: 20px;

    .label {
      font-weight: bold;
    }

    .methods {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;

      .method {
        border: 1px solid #e0e0e0;
        padding: 0px;
        margin-bottom: 20px;
        flex-basis: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        margin-right: 10px;

        &:hover {
          background-color: #ddd;
          cursor: pointer;
        }
      }
    }
  }

  .btn-order {
    margin-top: 20px;
    margin-right: 20px;
  }
}
</style>
